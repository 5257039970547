<template>
  <div class="frontPage">
    <p>{{ initText }}</p>
    <p>{{ initText2 }}</p>
    <button @click="toRoute">点击后跳转</button>
    <p>
      <audio
        controls
        ref="audiosF"
        height="50"
        src="./audio/introduce_bg.mp3"
      ></audio>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      initText: "",
      initText2: "",
      detailInfo: {
        streamUrl:
          "https://wjk.zhuneng.cn/data/minio/leo/upload/20230226/%E4%B8%BA%E7%88%B1%E5%8A%A0%E6%B2%B9%E4%B8%BA%E5%AE%B6%E6%8A%A4%E8%88%AA%E5%BC%A0%E9%A6%A8%E5%A7%9A_1677378859109.m4a",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    toRoute() {
      this.$router.push({
        name: "introducePage",
      });
    },
    init() {
      const vm = this;
      if (window.WeixinJSBridge) {
        window.WeixinJSBridge.invoke("getNetworkType", {}, (res) => {
          vm.$refs.audiosF.load();
          setTimeout(() => {
            vm.$refs.audiosF.play();
          }, 300);
        });
      } else {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            window.WeixinJSBridge.invoke("getNetworkType", {}, (res) => {
              vm.$refs.audiosF.load();

              setTimeout(() => {
                vm.$refs.audiosF.play();
              }, 300);
            });
          },
          false
        );
      }

      // const that = this;
      // //--创建页面监听，等待微信端页面加载完毕 触发音频播放
      // document.addEventListener("WeixinJSBridgeReady", function () {
      //   that.initText = "触发了WeixinJSBridgeReady回调";
      //   that.$nextTick(() => {
      //     document.getElementById("audiosF").play();
      //   });
      // });

      // //--创建触摸监听，当浏览器打开页面时，触摸屏幕触发事件，进行音频播放
      // document.addEventListener("touchstart", function () {
      //   that.initText = "触发了touchstart回调";
      //   that.$nextTick(() => {
      //     document.getElementById("audiosF").play();
      //   });
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.frontPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  button {
    padding: 20px;
    margin: 20px;
  }
}
// .introducePage {
//   position: relative;
//   box-sizing: border-box;
//   min-height: 100vh;
//   padding: 378px 0 80px;
//   background: url(./img/introducePage_bg.png) no-repeat top left;
//   background-size: 100% auto;
//   background-color: #dfeeff;
//   > div {
//     width: 100px;
//     height: 100px;
//     background: #000;
//   }
// }
</style>
